import { template as template_ffd136c217b04af9b952b4fc54f50984 } from "@ember/template-compiler";
const WelcomeHeader = template_ffd136c217b04af9b952b4fc54f50984(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
