import { template as template_e72e7ae7b0dd45ec859395f96128d492 } from "@ember/template-compiler";
const FKLabel = template_e72e7ae7b0dd45ec859395f96128d492(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
