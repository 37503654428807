import { template as template_041dd05211f149a69dd041b9eef29d0c } from "@ember/template-compiler";
import EmojiPickerContent from "discourse/components/emoji-picker/content";
const EmojiPickerDetached = template_041dd05211f149a69dd041b9eef29d0c(`
  <EmojiPickerContent
    @close={{@close}}
    @term={{@data.term}}
    @didSelectEmoji={{@data.didSelectEmoji}}
    @context={{@data.context}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmojiPickerDetached;
